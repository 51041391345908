import React from 'react'

import { Link } from 'gatsby'

import Input from '../input'

import styles from './style.module.scss'

import Select from '../select'

import Button from '../button'

import MarketingImage from '../../images/marketing.svg'

import Form from '../form'

import { TiInfoLarge } from 'react-icons/ti'

function chunk(arr, chunkSize) {
    var r = []
    for (var i = 0, len = arr.length; i < len; i += chunkSize)
        r.push(arr.slice(i, i + chunkSize))
    return r
}

const AMENITIES = [
    'Balcony',
    'Barbecue',
    'Built in Kitchen Appliances',
    'Built in Wardrobes',
    'Central AC/Heating',
    'Child Friendly',
    'Concierge Service',
    'Covered Parking',
    'Fitness Center',
    'Garages',
    'Gated Access',
    'Laundry Facility',
    'Maid Service',
    'Maids Room',
    'Pets Allowed',
    'Private Garden',
    'Private Gym',
    'Private Jacuzzi',
    'Private Pool',
    'Public Transportation Access',
    'Sauna',
    'Security',
    'Shared Gym',
    'Shared Pool',
    'Shared Spa',
    'Study',
    'Terrace',
    'View of Landmark',
    'View of Water',
    'Walk-in Closet'
]

export default () => {
    return (
        <div className={styles.listPropertyForm}>
            <div className="wrapper">
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <h1>List property</h1>
                        <p>
                            We match your property listing with enquiries
                            submitted by our community.
                        </p>
                        <p>
                            Be good! Don’t Let Our System Catch You Spamming. We
                            are a Pleasant Environment for Property Owners &
                            Real Estate Professionals.
                        </p>
                        <Form
                            className={styles.form}
                            requiredFields={[
                                'first_name',
                                'last_name',
                                'email',
                                'phone'
                            ]}
                        >
                            <h2>Personal Information</h2>
                            <Select label="Title">
                                <option value="Mr.">Mr. </option>
                                <option value="Ms.">Ms. </option>
                                <option value="Mrs.">Mrs. </option>
                            </Select>
                            <Input type="text" label="First name" required />
                            <Input type="text" label="Last name" required />
                            <Input type="email" label="Email" required />
                            <Input type="text" label="Phone" required />
                            <h2>Property Details</h2>
                            <div className={styles.advise}>
                                <TiInfoLarge className={styles.info} />
                                <div>
                                    It is advisable to list the details in the
                                    downloadable excel sheet and upload it with
                                    the property docs for our reference.
                                    <a
                                        href="https://dashboard.propertyeportal.com/wp-content/uploads/2018/12/List-for-free-on-propertyeportal.xlsx"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={styles.link}
                                    >
                                        Download
                                    </a>
                                </div>
                            </div>
                            <Input
                                type="text"
                                label="Property Title"
                                required
                            />
                            <Select label="Property type">
                                <option value="apartment">Apartment</option>
                                <option value="building">Building</option>
                                <option value="commercial_building">
                                    Commercial Building
                                </option>
                                <option value="land">Land</option>
                                <option value="office">Office</option>
                                <option value="penthouse">Penthouse</option>
                                <option value="residential_building">
                                    Residential Building
                                </option>
                                <option value="town_house">Town House</option>
                                <option value="warehouse">Warehouse</option>
                            </Select>
                            <label htmlFor="property_for">Property For</label>

                            <Input
                                type="checkbox"
                                name="property_for[]"
                                label={'Sale'}
                            />
                            <Input
                                type="checkbox"
                                name="property_for[]"
                                label={'Rent'}
                            />

                            <Input type="number" label="Sale or rent price" />
                            <Input type="text" label="Area size" />
                            <Input
                                type="radio"
                                name="area_size_type"
                                labels={['SqFt', 'SqM']}
                                containerClassName={styles.marginTop}
                            />
                            <Select label="Bedrooms">
                                <option value="studio">Studio</option>
                                <option value="1 bedroom">1 bedroom</option>
                                <option value="2 bedrooms">2 bedrooms</option>
                                <option value="3 bedrooms">3 bedrooms</option>
                                <option value="4 bedrooms">4 bedrooms</option>
                                <option value="5 bedrooms">5 bedrooms</option>
                                <option value="5+ bedrooms">5+ bedrooms</option>
                            </Select>

                            <label>Amentities</label>

                            <div className="row">
                                {chunk(AMENITIES, 2).map((chunk, i) => (
                                    <div className="col-xs-6" key={i}>
                                        {chunk.map(a => (
                                            <Input
                                                type="checkbox"
                                                name="amentities[]"
                                                label={a}
                                                key={a}
                                            />
                                        ))}
                                    </div>
                                ))}
                            </div>
                            <Input type="textarea" label="Message" />
                            <label>
                                <span className={styles.fileUploadSpan}>
                                    Upload property documents and photos
                                </span>
                                <input type="file" name="files" multiple />
                            </label>

                            <Input
                                type="checkbox"
                                label="I want propertyeportal.com to match my enquiry with suitable opportunities."
                            />
                            <Input type="checkbox" name="accept_privacy_policy">
                                I agree to the propertyeportal
                                <Link to="/privacy-policy">privacy policy</Link>
                            </Input>
                            <Button>Submit</Button>
                        </Form>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <MarketingImage className={styles.art} />
                    </div>
                </div>
            </div>
        </div>
    )
}
