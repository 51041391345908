import React, { useState } from 'react'

import cn from 'classnames'

import styles from './style.module.scss'

import { FaChevronDown, FaStarOfLife } from 'react-icons/fa'

import { encodeID } from '../input'

export default ({ children, label = '', required, ...props }) => {
    let name = encodeID(label)
    const [empty, setEmpty] = useState(true)

    if (empty && (props.multiple || props.defaultValue)) {
        setEmpty(false)
    }

    return (
        <div className={styles.selectContainer}>
            <label htmlFor={name}>
                {label}
                {required ? <FaStarOfLife className={styles.required} /> : null}
            </label>
            <select
                className={cn(styles.select, { [styles.empty]: empty })}
                name={name}
                defaultValue={props.multiple ? [] : ''}
                {...props}
                onBlur={e => setEmpty(!e.target.value)}
            >
                <option value="" disabled>
                    -- please select --
                </option>
                {children}
            </select>
            {!props.multiple && (
                <FaChevronDown className={styles.chevronDown} />
            )}
        </div>
    )
}
